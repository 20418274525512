@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
._3JFxkPFXMkWMSOdjiU3l9Z {
    background: #F5F5F5;
    padding: 1em 0;
    width: calc(100vw + 1.3em);
}

._2LzzVl_7vhbMJCaljqo6BG {
    font-size: xx-large;
    font-weight: bold;
}

._9FNeKwPkn7PWi0zQILntd {
    font-size: xx-large;
    font-weight: bold;
    color: #a9a9a9;
}
._3zP2xN7oSboGc1JFf_Vt5s, ._1F4_CHjBxO9XLtL9KOBYuy {
    margin-left: 1em;
}

._1F4_CHjBxO9XLtL9KOBYuy:enabled {
    background-color: white !important;
    color: black !important;
}

@media screen and (max-device-width: 840px) {
    ._2y2nO-R2cfHTx7AFAvQaEe {
        font-size: xx-large !important;
    }
    ._3zP2xN7oSboGc1JFf_Vt5s, ._1F4_CHjBxO9XLtL9KOBYuy {
        padding-top: 0.25em !important;
        padding-bottom: 1.5em !important;
    }
}

._2y2nO-R2cfHTx7AFAvQaEe {
    margin-top: 1em;
    margin-left: 1em;
    text-align: left;
}

html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h5, h6, p, ul {
    margin: 0;
    padding: 0;
}

._1de834UhOUq_nEikBzTMoB {
    padding: 10px;
}

._2oUOH7kRs6hU-JgOq3GYGw {
    margin-top: 50px;
    padding: 10px;
    text-align: center;
}

._3C2xxWcKSNHrBRNcIlLGTX {
    display: flex;
    overflow: hidden;
}

@media screen and (max-device-width: 840px) {
    ._26iWXZRi4wS4QjRC6IlfJL {
        margin-left: -14.5em !important;
        float: none !important;
    }

    ._2-HDd9NwZ5PZTGLhqB972v {
        width: 100vw !important;
        margin: auto !important;
        height: auto !important;
        overflow-y: scroll !important;
        float: none !important;
    }

    ._20CzfkKERJvHa79Fd40Z4X {
        width: 100% !important;
        margin-left: 0.5em !important;
    }

    h1 {
        font-size: xx-large !important;
        line-height: 120% !important;
        font-weight: bold !important;
    }

    section {
        font-size: xx-large !important;
        line-height: 120% !important;
    }

    h2, h3, h4, select, text, p {
        font-size: xx-large !important;
    }

    label {
        font-size: xx-large !important;
        line-height: 100% !important;
    }

    button {
        font-size: xx-large !important;
        margin: 0.2em !important;
    }

    .iB1VDv-rnFwzZWh1PehIp {
        font-size: xx-large !important;
        margin: 0.2em 0.2em 0.2em 0em !important;
        width: 100% !important;
        padding-top: 0.25em !important;
        padding-bottom: 1.5em !important;
        background-color: white !important;
        color: black !important;
    }

    ._2fViEDii_e29v6sc8KezSd {
        padding: 1em;
    }

    ._1Xkvnn7HqyDg0EFihpaTd {
        float: left;
        padding: 0.35em;
    }

    ._13cTFIKKgHzvCKesyFWOfB {
        display: inline-flex;
    }

    ._2cDNY5UnhG_eRLrtFkZgCs {
        font-size: x-large;
        font-weight: bold;
        color: red;
    }
}

._26iWXZRi4wS4QjRC6IlfJL {
    margin: 0em 0em 0em 0em;
    float: right;
}

/*TODO: change this. */
._2-HDd9NwZ5PZTGLhqB972v {
    width: 24em;
    margin: 0em 0em 3em 1em;
    float: left;
    height: calc(100vh - 9em);
    overflow-y: auto;
}

._2UW4luTmI9TiTdVG5V2uzT {
    margin-left: auto;
    padding: 0.5em 0em 0.5em 1em;
    width: 24em;
    bottom: 0;
    background-color: #fff;
    text-align: center;
}

._20CzfkKERJvHa79Fd40Z4X {
    width: 18em;
}

._2nJEXILc6_QZuze2lHFA4A {
    padding: 0 !important;
}

._1xTtjX8LZxZBsn1V4FT4Ja {
    color: #FF0000;
    padding: 1em;
    font-weight: bold;
}

._29-XfaOR5g2kGXT5FDSXfr {
    margin: 25vh auto;
    padding: 3.5em 0 0 1em;
    left: 42.55%;
    position: absolute;
    width: 10em;
    height: 10em;
    color: #29ABE2;
    border: 1em inset #29ABE2;
    border-radius: 50%;
}

._69XSFocgjHjTFhXypBbkI {
    background-color: rgba(0, 0, 0, 0.6);
}

._21OQd9sZsb73Otnp3ky-0f {
    border-radius: 50%;
    width: 10em;
    height: 10em;
    border: 1em inset #29ABE2;
    -webkit-animation: _1OLsMUbuT19x3i4sPCt6IP 4s linear infinite;
    -moz-animation: _1OLsMUbuT19x3i4sPCt6IP 4s linear infinite;
    animation: _1OLsMUbuT19x3i4sPCt6IP 4s linear infinite;
    margin: 25vh auto;
    z-index: 1000;
    position: absolute;
    left: 42.55%;
}

.G8tWPZBe_XbIKzh_aQJhC {
    background-color: rgba(0, 0, 0, 0.6);
    height: calc(100vh - 8em);
    width: 100%;
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    margin: 8.2em 0 0 0;
}

@-webkit-keyframes _1OLsMUbuT19x3i4sPCt6IP {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes _1OLsMUbuT19x3i4sPCt6IP {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes _1OLsMUbuT19x3i4sPCt6IP {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

._3QqOaspZ3opIwlljjTIEn9 {
    padding-bottom: 1em !important;
}

._3x5XgI8qVovEAoxGMSxMvM {
    font-size: 200%;
}

._3iIgPXa1EnB_sRoN4PI5DX {
    font-weight: bold;
    font-size: larger;
}

.i30a0ExcuZUULdt0TCuv5 {
    color: #000000;
    font-size: larger;
}

._18F5xRM90XXMC6tcKAxJ4v {
    font-weight: bold !important;
}

._2q-Hw8yM-0uJTRnOqv67RZ {
    font-size: larger !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
}

.aCMbQOiIXPBmBDBhvMIeH {
    color: red;
    cursor: pointer;
}

._1zlWXqV0eBmnq9GCbLO2TR {
    color: white;
    display: inline-block;
    padding-left: 2.5em;
    text-indent: -1em;
}

._1w_qGp-dsHVo1y8fAfAwZc {
    padding: 0.2em;
}

._22pnwTahdlK5a82cwtrs3n {
    font-weight: bold;
    color: white;
}

.t21lkXQDO_E83XrQhJUnb {
    font-weight: bold;
    filter: invert(100%);
}

._1Sizc7ngYLf1FFv5mamBG7 {
    position: fixed;
    z-index: 1;
    width: 15em;
    bottom: 0;
    left: 57%;
    margin: 0 auto 0.9em auto;
    background-color: rgba(0, 60, 136, 0.6) !important;
    border-radius: 0.2em;
    border: solid rgba(255, 255, 255, 0.7);
}

._2UpHflQ_OS7iqVliyBMKur {
    filter: invert(100%);
}

._2ynyloOz8myvBsgY-8dlfA {
    width: 100%;
}

._12yCcRLtREXwYppENs0EyQ {
    cursor: pointer;
    margin-left: -1em;
    position: relative;
}

.-C4bve_h6ZmELbSud7230 {
    cursor: pointer;
    float: right;
    color: white;
    margin-right: 20px;
}

.-C4bve_h6ZmELbSud7230 i {
    color: white;
}

html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h5, h6, p, ul {
    margin: 0;
    padding: 0;
}

._1OG9-Pmzu_d18CSZ_0wFgW {
    padding: 10px;
}

._25BAlNm1qWZG2Yp7diGkFo {
    margin-top: 50px;
    padding: 10px;
    text-align: center;
}

._3ymyGT7JL4ccgxr9fi7Di {
    display: flex;
    overflow: hidden;
}

@media screen and (max-device-width: 840px) {
    .Ik65c7e3A-a_4zfy0iTZS {
        margin-left: -14.5em !important;
        float: none !important;
    }

    .bxAv-FC4jCClWd1W_8gTY {
        width: 100vw !important;
        margin: auto !important;
        height: auto !important;
        overflow-y: scroll !important;
        float: none !important;
    }

    ._1UVLJrqvoQfKgxsz5FNISu {
        width: 100% !important;
        margin-left: 0.5em !important;
    }

    h1 {
        font-size: xx-large !important;
        line-height: 120% !important;
        font-weight: bold !important;
    }

    section {
        font-size: xx-large !important;
        line-height: 120% !important;
    }

    h2, h3, h4, select, text, p {
        font-size: xx-large !important;
    }

    label {
        font-size: xx-large !important;
        line-height: 100% !important;
    }

    button {
        font-size: xx-large !important;
        margin: 0.2em !important;
    }

    ._3N15dtfx9AXWADOu3hhMix {
        font-size: xx-large !important;
        margin: 0.2em 0.2em 0.2em 0em !important;
        width: 100% !important;
        padding-top: 0.25em !important;
        padding-bottom: 1.5em !important;
        background-color: white !important;
        color: black !important;
    }

    ._1OpUOH9GyP2Pr6c42zWjbh {
        padding: 1em;
    }

    ._94sAupeAnyZ--m6YICgJN {
        float: left;
        padding: 0.35em;
    }

    ._2DfSViC_LOasvM-Pb9r0dJ {
        display: inline-flex;
    }

    ._2_1VApc5fsqkF7xHOQrwkL {
        font-size: x-large;
        font-weight: bold;
        color: red;
    }
}

.Ik65c7e3A-a_4zfy0iTZS {
    margin: 0em 0em 0em 0em;
    float: right;
}

/*TODO: change this. */
.bxAv-FC4jCClWd1W_8gTY {
    width: 24em;
    margin: 0em 0em 3em 1em;
    float: left;
    height: calc(100vh - 9em);
    overflow-y: auto;
}

.O0ps78JMD_PSs6IDoOD6o {
    margin-left: auto;
    padding: 0.5em 0em 0.5em 1em;
    width: 24em;
    bottom: 0;
    background-color: #fff;
    text-align: center;
}

._1UVLJrqvoQfKgxsz5FNISu {
    width: 18em;
}

._3itp6MIo5dLe8xTS2nMzQR {
    padding: 0 !important;
}

._2ZLVaIy3k1hBAkkyrX6ulr {
    color: #FF0000;
    padding: 1em;
    font-weight: bold;
}

._3SiKBltP6HiApg_l7KHEla {
    margin: 25vh auto;
    padding: 3.5em 0 0 1em;
    left: 42.55%;
    position: absolute;
    width: 10em;
    height: 10em;
    color: #29ABE2;
    border: 1em inset #29ABE2;
    border-radius: 50%;
}

._3JQhHjM5oGogs7W_NwuOWV {
    background-color: rgba(0, 0, 0, 0.6);
}

.TDfNmWq40U44k2CGIPkEb {
    border-radius: 50%;
    width: 10em;
    height: 10em;
    border: 1em inset #29ABE2;
    -webkit-animation: _2PBX1afdZYZERIr0Le1-vB 4s linear infinite;
    -moz-animation: _2PBX1afdZYZERIr0Le1-vB 4s linear infinite;
    animation: _2PBX1afdZYZERIr0Le1-vB 4s linear infinite;
    margin: 25vh auto;
    z-index: 1000;
    position: absolute;
    left: 42.55%;
}

._2nP-n8sJfD9YFs-KryKtNj {
    background-color: rgba(0, 0, 0, 0.6);
    height: calc(100vh - 3.8em);
    width: 100%;
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    margin: 3em 0 0 0;
}

@-webkit-keyframes _2PBX1afdZYZERIr0Le1-vB {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes _2PBX1afdZYZERIr0Le1-vB {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes _2PBX1afdZYZERIr0Le1-vB {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

._1Byk3US_ueJB1gF_g9Q3TF {
    padding-bottom: 1em !important;
}

.blmXLlpTIfZLRm6ueMKg {
    font-size: 200%;
}

._35_9bioCwySkMbq4_9sMGG {
    font-weight: bold;
    font-size: larger;
}

._2owHncdAM2diiRwmdutP4a {
    color: #000000;
    font-size: larger;
}

._3wWG7vr7cRdFF0xLO95wct {
    font-weight: bold !important;
}

._2yVksrYzwNpir-uopnfxtf {
    font-size: larger !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
}

.wUR5ayaqj0dQp4dMPGXBP {
    color: red;
    cursor: pointer;
}

.-aTEI1D8V89kUNHGQ8fcC {
    color: white;
    display: inline-block;
    padding-left: 2.5em;
    text-indent: -1em;
}

._3yLj5O8IoX65pR4jagbl59 {
    padding: 0.2em;
}

.W1t71qPfxO5PjOgbU1jp6 {
    font-weight: bold;
    color: white;
}

.idPMdLd84YWi64AwQezX {
    font-weight: bold;
    filter: invert(100%);
}

.y__rIBwsYSdQ6nE-ml5ti {
    position: fixed;
    z-index: 1;
    width: 15em;
    bottom: 0;
    left: 57%;
    margin: 0 auto 0.9em auto;
    background-color: rgba(0, 60, 136, 0.6) !important;
    border-radius: 0.2em;
    border: solid rgba(255, 255, 255, 0.7);
}

._2l0qA2ysYBwfJOr5c_0Aab {
    filter: invert(100%);
}

._3tCjKvdpbloCOV6ceCWKeI {
    width: 100%;
}

._1B73mm12bLTRatTxP-EvJW {
    cursor: pointer;
    margin-left: 1em;
    float: right;
}

._1wsE4v6OCqYKwOg8XbnM58 {
    cursor: pointer;
    float: right;
    color: white;
    margin-right: 20px;
}

._1wsE4v6OCqYKwOg8XbnM58 i {
    color: white;
}

@media screen and (max-device-width: 840px) {
    ._3jdPAVVdQa9essHaPJYeFI {
        width: 100vw !important;
        margin: auto !important;
        height: calc(100vh - 18em) !important;
        overflow-y: scroll !important;
        float: none !important;
    }

    ._3io09Xk8yfhWzEh-b6IbO0 {
        margin-left: auto !important;
        padding: 0.5em 0em 0.5em 1em !important;
        width: 100vw !important;
        background-color: #fff !important;
    }
}

._3jdPAVVdQa9essHaPJYeFI {
    width: 25em;
    margin: 4em 0 3em 1em;
    float: left;
    height: calc(100vh - 8.5em);
    overflow-y: auto;
}

._3io09Xk8yfhWzEh-b6IbO0 {
    margin-left: auto;
    padding: 0.5em 0em 0.5em 1em;
    width: 24em;
    bottom: 0;
    position: absolute;
    background-color: #fff;
}

.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 2px solid blue;
}

.ol-mouse-position {
  top: 8px;
  right: 8px;
  position: absolute;
}

.ol-scale-line {
  background: rgba(0,60,136,0.3);
  border-radius: 4px;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  position: absolute;
}
.ol-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 10px;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
}
.ol-overlay-container {
  will-change: left,right,top,bottom;
}

.ol-unsupported {
  display: none;
}
.ol-viewport .ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.ol-control {
  position: absolute;
  background-color: rgba(255,255,255,0.4);
  border-radius: 4px;
  padding: 2px;
}
.ol-control:hover {
  background-color: rgba(255,255,255,0.6);
}
.ol-zoom {
  top: .5em;
  left: .5em;
}
.ol-rotate {
  top: .5em;
  right: .5em;
  transition: opacity .25s linear, visibility 0s linear;
}
.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s;
}
.ol-zoom-extent {
  top: 4.643em;
  left: .5em;
}
.ol-full-screen {
  right: .5em;
  top: .5em;
}
@media print {
  .ol-control {
    display: none;
  }
}

.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: white;
  font-size: 1.14em;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: .4em;
  background-color: rgba(0,60,136,0.5);
  border: none;
  border-radius: 2px;
}
.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}
.ol-zoom-extent button {
  line-height: 1.4em;
}
.ol-compass {
  display: block;
  font-weight: normal;
  font-size: 1.2em;
  will-change: transform;
}
.ol-touch .ol-control button {
  font-size: 1.5em;
}
.ol-touch .ol-zoom-extent {
  top: 5.5em;
}
.ol-control button:hover,
.ol-control button:focus {
  text-decoration: none;
  background-color: rgba(0,60,136,0.7);
}
.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}
.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}


.ol-attribution {
  text-align: right;
  bottom: .5em;
  right: .5em;
  max-width: calc(100% - 1.3em);
}

.ol-attribution ul {
  margin: 0;
  padding: 0 .5em;
  font-size: .7rem;
  line-height: 1.375em;
  color: #000;
  text-shadow: 0 0 2px #fff;
}
.ol-attribution li {
  display: inline;
  list-style: none;
  line-height: inherit;
}
.ol-attribution li:not(:last-child):after {
  content: " ";
}
.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}
.ol-attribution ul, .ol-attribution button {
  display: inline-block;
}
.ol-attribution.ol-collapsed ul {
  display: none;
}
.ol-attribution.ol-logo-only ul {
  display: block;
}
.ol-attribution:not(.ol-collapsed) {
  background: rgba(255,255,255,0.8);
}
.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0;
  height: 1.1em;
  line-height: 1em;
}
.ol-attribution.ol-logo-only {
  background: transparent;
  bottom: .4em;
  height: 1.1em;
  line-height: 1em;
}
.ol-attribution.ol-uncollapsible img {
  margin-top: -.2em;
  max-height: 1.6em;
}
.ol-attribution.ol-logo-only button,
.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  top: 4.5em;
  left: .5em;
  height: 200px;
}
.ol-zoomslider button {
  position: relative;
  height: 10px;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  left: 0.5em;
  bottom: 0.5em;
}
.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: 0 4px 0 0;
}
.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: inline-block;
}
.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid #7b98bc;
  height: 150px;
  margin: 2px;
  width: 150px;
}
.ol-overviewmap:not(.ol-collapsed) button{
  bottom: 1px;
  left: 2px;
  position: absolute;
}
.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none;
}
.ol-overviewmap:not(.ol-collapsed) {
  background: rgba(255,255,255,0.8);
}
.ol-overviewmap-box {
  border: 2px dotted rgba(0,60,136,0.7);
}

@media screen and (max-device-width: 840px) {
    .uKAHGhS-ZbNvuNUdePL4A {
        height: calc(100vh - 20em) !important;
        width: calc(100vw - 1em) !important;
        cursor: pointer;
    }

    ._3oWx76QZQfqEF5mnsF66ms {
        font-size: x-large !important;
    }
}

.uKAHGhS-ZbNvuNUdePL4A {
    height: calc(100vh - 3.8em);
    width: calc(100vw - 26.2em);
    cursor: pointer;
}

._3WTOSOPXNnHYCb7tfkVTzB {
    width: 20px;
    height: 20px;
    border: 1px solid #088;
    border-radius: 10px;
    background-color: #0FF;
    opacity: 0.5;
}

._3oWx76QZQfqEF5mnsF66ms {
    position: absolute;
    background-color: white;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    bottom: 28px;
    left: -48px;
    min-width: 28em;
    font-size: 80%;
    cursor: default;
    z-index: 1;
}

._3oWx76QZQfqEF5mnsF66ms:after, ._3oWx76QZQfqEF5mnsF66ms:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

._3oWx76QZQfqEF5mnsF66ms:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

._3oWx76QZQfqEF5mnsF66ms:before {
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.-Md6ZCaJKHwxmNdTZ6CFh {
    text-decoration: none;
    position: absolute;
    top: 9px;
    right: 8px;
    color: #ffffff;
}

.-Md6ZCaJKHwxmNdTZ6CFh:after {
    color: #ffffff;
}

.PdOEfm2TgB7xUkynEi3-M {
    bottom: .5em;
    left: .5em;
    border-radius: 0.15em;
}

._2CuwfCVU0Up_Xg8QYVSb_i {
    top: 6.3em;
}

._1N3UtO7foMMNkYtWTHF_Th {
    top: 8.15em;
}

._1M0lLNWG1nI74hSkOeCbMN {
    top: 9.95em;
}

._36IzchsFGMSm1EhflEIMJo {
    top: 11.75em;
}

.iCQc8oG8O-QBNVUvOAVSn {
    left: .5em;
    border-radius: 0.15em;
    visibility: hidden;
}

._1aCMnyqsO1OxlFdqqkbNzj {
    margin: auto;
    border-collapse: separate;
    border-spacing: 0.4em;
    min-width: 28.1em;
    padding-left: 0.5em;
    background-color: #dcdcdc;
}

._1cLo-HxuZ35sEYf6Xa8rQZ {
    color: #ffffff;
    padding: 0.5em;
    font-weight: normal;
    font-size: x-large;
}

._2YM67PrL9J79tB3BUpSawn {
    padding: 0.2em 0.2em 0.2em 1em;
}

.csdoZkO9rbzKDIGtQcOVH {
    border-bottom: 0.2em solid #808080;
    min-height: 0;
}

.u9LoLMbshKqTu4D_k6dyY {
    margin-bottom: 0.5em;
    overflow-y: auto;
    line-height: 1.5em;
}

._3Bq5QCJ4OjRKG4hHusmENT {
    text-align: center;
    padding-top: 1em;
    font-size: larger;
    font-weight: bold;
    margin-top: 2.5em;
    position: relative;
    width: 4.9em;
    height: 3.5em;
    border-radius: 0% 0% 10% 10%;
}

._3Bq5QCJ4OjRKG4hHusmENT:before {
    content: '';
    border-width: 0em 2.4em 2.2em 2.5em;
    border-color: transparent;
    border-bottom-color: inherit;
    border-style: solid;
    position: absolute;
    width: 0em;
    height: 0em;
    left: 0em;
    top: -2.2em;
}

._2GVcG-6XR5e_lCtIAxlyF7 {
    text-align: center;
    padding-top: 1.5em;
    font-size: larger;
    font-weight: bold;
    margin-bottom: 2.5em;
    position: relative;
    width: 4.9em;
    height: 3.5em;
    border-radius: 10% 10% 0% 0%;
}

._2GVcG-6XR5e_lCtIAxlyF7:before {
    content: '';
    border-width: 2.2em 2.5em 0em 2.4em;
    border-color: transparent;
    border-top-color: inherit;
    border-style: solid;
    position: absolute;
    width: 0em;
    height: 0em;
    left: 0em;
    bottom: -2.15em;
}

.uSEeq_i1jNpanYP-8n060 {
    text-align: center;
    padding-top: 1.8em;
    font-size: larger;
    font-weight: bold;
    width: 5em;
    height: 5em;
    border-radius: 50%;
}

._3vbuaCkZjHcv4LFN4dq2tv {
    position: absolute;
    top: 5.8em;
    right: 0.5em;
}

._29U4HhaAgrOdTOqTOo4jJ4 {
    width: 13em;
    padding: 0.5em;
    background: #FFFFFF;
    border: 0.2em solid #F5F5F5;
    border-radius: 5%;
}

@media screen and (max-device-width: 840px) {
    ._29U4HhaAgrOdTOqTOo4jJ4 {
        font-size: x-large;
    }
}

.pqDx4JgCi0lsYcGXAsL49 {
    border-bottom: 1em solid;
    border-bottom-color: inherit;
    border-right: 0.5em solid transparent !important;
    border-left: 0.5em solid transparent !important;
    display: inline-block;
}

._38G8aJ7WvKS1Osef7BRlH1 {
    border-top: 1em solid;
    border-top-color: inherit;
    border-right: 0.5em solid transparent !important;
    border-left: 0.5em solid transparent !important;
    display: inline-block;
}

._2GXR2MPySW-MLolKraRF0v {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    display: inline-block;
}

._2ReZ2hn5_CI-Y77ombFrp4 {
    text-align: center;
    display: block;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-weight: bold;
    text-transform: uppercase;
}

._1qktlhV1DxGwzOzTy3Asef {
    text-align: center;
    display: block;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-weight: bold;
}

@media screen and (max-device-width: 840px) {
    ._1aCMnyqsO1OxlFdqqkbNzj, .u9LoLMbshKqTu4D_k6dyY, ._2ReZ2hn5_CI-Y77ombFrp4, ._1qktlhV1DxGwzOzTy3Asef, ._2o1cr7A1FjKkxeomz_tul_ {
        font-size: xx-large !important;
    }

    ._1cLo-HxuZ35sEYf6Xa8rQZ {
        font-size: 200% !important;
    }

    ._3oWx76QZQfqEF5mnsF66ms {
        min-width: 30em !important;
    }
}

._2o1cr7A1FjKkxeomz_tul_ {
    margin: auto;
    border-collapse: separate;
    border-spacing: 0.4em;
    min-width: 28em;
    padding-left: 0.5em;
    line-height: 1.2em;
}

._1eWgx_ckGNtdoL8oTc-S82 {
    color: #808080;
}

.WhEh-8IultH6zU23WJOv_ {
    vertical-align: top !important;
}

._1ZqRS27fcd8syis5rbDv81 {
    color: #FF0000;
}

.SUGuvR18AvI9Drs51R2QB {
    padding-left: 4em;
}

.R4ZPNrNGf_UoXvHcQiePg {
    display: inline-block;
    width: 300px;
    height: 500px;
    border: 1px black solid;
    margin: 50px;
    padding: 10px;
    background: white;
    overflow: auto;
}

._3TV5yA4d_OfzipLlqACFyY {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    height: 34px;
    width: 280px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

._3xAPvxXCY5AYZT-MTze6RR {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

._3GK0uH2z0k-mJuD_XT18Bi.hlspoRj8lGHvwKIDSQ6BT select {
    border: 1px solid #ccc;
    font-size: 16px;
    height: 34px;
    width: 268px;
}

._3WJ8_qqKHLPx1Pkk4xUbHU {
    float: left;
    font-size: smaller;
    width: 100%;
    overflow-y: auto;
}

._1CM-sBBP-rW5moRgXybAqB {
    float: right;
    width: 1em;
    cursor: pointer;
}

._3hzsO1XiPpCGlznkMWwV9m {
    float: right;
    width: 1.5em;
    cursor: pointer;
    padding-left: 0.33em;
}

.vxQfKySRRNg11mUq5XQVQ {
    float: right;
    width: 1em;
    cursor: pointer;
}

._2L7EBamtBovL8cGq8Ul-f0 {
    float: left;
    width: 15em;
}

._3NCofyORIyk0Q63t6QSps1 {
    color: red;
}

.gZl4Mif6wz07rCzT1T_3v {
    float: left;
    margin-right: 0.2em;
    margin-top: 0.2em;
}

._36r4ORE0LXymgOG80dvmw0 {
    float: left;
    margin-right: 0.2em;
}

._1pv32bL8uQuurU-cBc0ylD {
    float: left;
    margin-right: 0.2em;
    color: #FFFFFF;
    background: #000000;
    border-radius: 100%;
}

._2B-lJbodWyZ0rDvomgcnvN {
    padding: 0.2em 0.6em 0;
}

.h0hTG8sSPO9VXLaNyW6Yi {
    width: 22em;
    margin: 1em 0 0 0;
    padding: 0.5em 1em 1em 1em;
    text-align: left;
    display: inline-block;
    overflow-y: auto;
    overflow-x: auto;
}

._1cxGXH7WrKTVoIeotj9VbW {
    margin-top: 0.6em;
    margin-bottom: -0.5em;
    width: 80%;
}

._3TJA7cG5OX4qZVK966xX1_ {
    border-bottom-style: solid;
    border-bottom-width: 0.1em;
    padding-bottom: 1em;
    margin-bottom: 1em;
}

._294cmbN_jtI02eGmwbxeAe {
    line-height: 1em;
    padding: 0 1em 0 1em;
}

hr._3GHQCWEiwuJCvS6iYBX9hi {
    border-top: 0.5em double #808080;
    width: 16em;
}

._1XZX6OqSaQhuWJGJ5tZCzL {
    cursor: pointer;
    margin: auto;
}

@media screen and (max-device-width: 840px) {
    .h0hTG8sSPO9VXLaNyW6Yi {
        width: 100% !important;
        margin-left: 0.5em !important;
    }

    ._3NCofyORIyk0Q63t6QSps1 {
        font-size: larger;
    }

    .gZl4Mif6wz07rCzT1T_3v {
        font-size: larger;
        margin-top: 0;
    }

    ._36r4ORE0LXymgOG80dvmw0 {
        font-size: larger;
    }

    ._1pv32bL8uQuurU-cBc0ylD {
        font-size: larger;
    }

    section > div > input {
        font-size: larger;
    }

    section > h2 {
        line-height: unset !important;
    }

    ._3hzsO1XiPpCGlznkMWwV9m, ._1CM-sBBP-rW5moRgXybAqB {
        float: right;
        width: 1em;
        cursor: pointer;
        padding-left: 0 !important;
    }

    ._3hzsO1XiPpCGlznkMWwV9m {
        font-size: larger;
    }

    a._1XZX6OqSaQhuWJGJ5tZCzL > i {
        font-size: xx-large !important;
    }
}

._1m9Nm2aGIC3g93sw1FAMQN {
    border: #FFFFFF;
    margin: 0.2em;
    padding: 0.2em;
    border-radius: 0.5em;
    background-color: #D4DCE7;
    width: 10em;
}
._3uwtuA6RPT9aPeC_TmjJbe {
	padding: 10px 0px;
	border-top: 1px solid gray;
}

._1KL6mZOvJaxLe4S9SedKAk {
	margin-left: 1em;
	margin-top: 0.6em;
	margin-bottom: -0.5em;
	width: 90%;
}

._2ViMGtZEzhwi_E-YUp0jTw {
	width: 1em;
}

.wfznt4gX0430ta0wl7l_I {
	margin-left: 1.1em;
}
._8YOD8h8UmCBi7_FQyHdtU {
    background: lightblue;
    display: table;
    width:100%;
}
        
input[type=button] {    
    vertical-align: middle;
    display: block;
    width: 50%;
    margin: 0 auto;
}
        
.S65tw1_NoBT5Fwxs9irzv {
    background: darkorange;
    display: table-cell;
    vertical-align: middle;
}

@media screen and (max-device-width: 840px) {
    ._3-vDIclJlkaMw2v8Wfn0Xt {
        margin-top: 1em !important;
        margin-left: 50% !important;
        --mdc-theme-secondary: #ff4081;
        --mdc-theme-secondary-light: #ff87b0;
        --mdc-theme-secondary-dark: #f80054;
    }
}

._3-vDIclJlkaMw2v8Wfn0Xt {
    margin: 1em 1em 1em 13em;
    --mdc-theme-secondary: #ff4081;
    --mdc-theme-secondary-light: #ff87b0;
    --mdc-theme-secondary-dark: #f80054;
}

._2VCCDssQpYVwuQCS_3Fgzn {
  display: inline-block;
}

@media screen and (max-device-width: 840px) {
    ._22oswnCu2wuqmAVMoRyFFU {
        width: 100vw !important;
        margin: auto !important;
        height: auto !important;
        overflow-y: scroll !important;
        float: none !important;
    }
    ._36NUA8eIlgR7Hi_dxKhuGd {
        width: 60em !important;
        margin: 1em !important;
    }
}

._2k6Tr61KoRaNl0eDcaxHYH {
    padding: 2em;
    text-align: center;
}

._2p-IuDcyMmf0WvyCVy8n10 {
    height: calc(100vh - 4em);
    width: calc(100vw - 25em);
}

._1e0ADvF3w5Nqi-ZUXlHse_ {
    margin: 4em 0em 0em 0em;
    float: right;
}

._22oswnCu2wuqmAVMoRyFFU {
    width: 25em;
    margin: 0em 0.1em 3em 0.1em;
    float: left;
    height: calc(100vh - 30em);
    overflow-y: auto;
}

._3bPytIY1vjNL8XIrg1Bl2 {
    display: flex;
}

._3sMcwBQ8GJPYNOxiAiLxif {
    position: fixed;
    width: 100%;
    margin-bottom: 5em;
    z-index: 100;
}

._3pqT1DehEveXu_I93jvhMb {
    max-height: 30em;
    width: 22.2em;
    margin: 1em 0.5em 0.5em 0.2em;
    padding: 1em 1em;
    text-align: left;
    display: inline-block;
    overflow-y: auto;
    overflow-x: auto;
}

._2YZQ8DqwcOgZfCQGYobxsi {
    float: left;
}

._2aqyaG_9OtTm5h3OyoNKKj {
    padding-top: 1em;
    padding-left: 1em;
    float: left;
    width: 15em;
}

._3gx27-An_qvfJDfrKANAjA {
    overflow-y: auto;
    height: 9em;
}

._21IhM4m34Hh_ScHkGqHaoP {
    margin-left: auto;
    text-align: center;
    padding: 0.5em 0.5em 0.5em 5em;
    position: fixed;
    bottom:0;
    z-index: 100;
    background-color: #ffffff;
}

._192HocGPPV-6I-uoAiRCNV {
    margin-top: 1em;
    margin-left: 1em;
    float: left;
    width: 45%;
}

._1TFmeJlKI_A7GSV6C0NjLc {
    float: left;
    margin-top: 1em;
    margin-left: 1em;
    text-align: left;
}

._17IBxOzmatFmCKw_dGQa3d {
    margin: 1em 0.1em 0.5em 1.1em;
    width: 22em;
}

._3JJFKyyW1szCzu35eJmeWe {
    margin-top: 2em;
}

.ncZPCMFnt_CSKB-s2pzjN {
    width: 9em;
}

._36NUA8eIlgR7Hi_dxKhuGd {
    max-height: 16em;
    overflow-y: auto !important;
    overflow-x: auto !important;
    width: 24em;
    margin: 1em 0em 0.5em 1em;
    padding: 0.2em 0.2em 0.2em 0em !important;
    top: 7.3em;
    z-index: 100;
    background-color: #ffffff;
    display: inline-block !important;
}

._20KT4m4ksjhTAee0aKdsmG {
    padding: 0.5em;
}
@media screen and (max-device-width: 840px) {
    ._2lRjhgP7KIUKIAIq9lj_qA {
        width: 100vw !important;
        margin: auto !important;
        height: auto !important;
        overflow-y: scroll !important;
        float: none !important;
    }

    ._1er29WEcbdkTMdNigFS9BE,
    ._3dHeNxCP16uXS6Hyv500OO,
    ._1fvgESSjHC3Umlmf_brkYJ {
        width: 100% !important;
        margin-left: 0.5em !important;
    }
}

._2lRjhgP7KIUKIAIq9lj_qA {
    width: 24em;
    float: left;
    height: calc(100vh - 10em);
    overflow-y: auto;
}

.fN_UIEk9VtmWygrT_X5RN {
    max-height: 30em;
    width: 22.2em;
    margin: 1em 0.5em 0.5em 0.2em;
    padding: 1em 1em;
    text-align: left;
    display: inline-block;
    overflow-y: auto;
    overflow-x: auto;
}

._2dv3ha908-5ZLBiNeuOWdg {
    width: 20em;
    padding: 1em;
    display: inline-block;
    margin-left: 2em;
    margin-bottom: 2em;
}

._20mCJwO89C8VgmG1BA5qj6 {
    margin-top: 2em;
    max-width: calc(100%) !important;
}

.ot6copK22WqdEUP6KN-L4 {
    width: 25em;
    margin-top: 2.5em !important;
    height: calc(100vh - 12em);
    overflow-y: auto;
}

._1oyBF7Dk_bKIFpIno8943v {
    margin-top: 4em;
    margin-left: 2em;
}

._3-BACTsK-g7S9aE0vTSGmP {
    padding: 0.2em 0.2em 0.2em 1em !important;
    width: 100%;
    position: absolute;
    background-color: #ffffff;
    z-index: 1;
}

._1YI3FZU64Al0BHR7biCLGJ {
    padding-left: 2em;
    list-style: none;
}

._2uIigtmhUlSx9Bf8BL99rE {
    width: 8em;
    margin-bottom: -1em;
}

._3yBgiyg1cdsKFNbdzNf_ZR {
    float: left;
    margin-top: 1em;
    margin-left: 1em;
    text-align: left;
}

._1Nws6gqZCkoAM0JHg7ISDf {
    margin-top: 1em;
    margin-left: 1em;
    float: left;
    width: 45%;
}

._1er29WEcbdkTMdNigFS9BE {
    width: 21em;
    margin: 0em 0.1em 1em 0.1em;
}

._3dHeNxCP16uXS6Hyv500OO {
    width: 21em;
    margin: 0em 0.1em 1em 0.1em;
}

._1fvgESSjHC3Umlmf_brkYJ {
    margin: 0em 0.1em 1em 0.1em;
    width: 21em;
}

.UHT26scPiAaPzkXloud28 {
    background-color: #3f51b5;
    padding: 0.5em;
    border-radius: 5em;
    font-weight: bold;
    color: white;
}

._1A4gqb102hog0W6TDMXyS5 {
    text-transform: capitalize;
}

._2mUpVGhC3mbP7nv6_KYa0q {
    position: absolute;
    top: 9em;
    right: 0.8em;
}

._2xB6QOwvgoRNqPyVIgK60O {
    float: right;
    cursor: pointer;
}

.cP3E6g82Pb1UWt_hnGR70 {
    color: red;
    cursor: pointer;
}

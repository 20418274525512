@media screen and (max-device-width: 840px) {
    .root {
        height: calc(100vh - 20em) !important;
        width: calc(100vw - 1em) !important;
        cursor: pointer;
    }

    .olPopup {
        font-size: x-large !important;
    }
}

.root {
    height: calc(100vh - 3.8em);
    width: calc(100vw - 26.2em);
    cursor: pointer;
}

.marker {
    width: 20px;
    height: 20px;
    border: 1px solid #088;
    border-radius: 10px;
    background-color: #0FF;
    opacity: 0.5;
}

.olPopup {
    position: absolute;
    background-color: white;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    bottom: 28px;
    left: -48px;
    min-width: 28em;
    font-size: 80%;
    cursor: default;
    z-index: 1;
}

.olPopup:after, .olPopup:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.olPopup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.olPopup:before {
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.olPopupCloser {
    text-decoration: none;
    position: absolute;
    top: 9px;
    right: 8px;
    color: #ffffff;
}

.olPopupCloser:after {
    color: #ffffff;
}

.olCenterButton {
    bottom: .5em;
    left: .5em;
    border-radius: 0.15em;
}

.olDrawCircleButton {
    top: 6.3em;
}

.olDrawSquareButton {
    top: 8.15em;
}

.olDrawCustomButton {
    top: 9.95em;
}

.olDrawClearButton {
    top: 11.75em;
}

.olSharedDrawStyles {
    left: .5em;
    border-radius: 0.15em;
    visibility: hidden;
}

.popup_table {
    margin: auto;
    border-collapse: separate;
    border-spacing: 0.4em;
    min-width: 28.1em;
    padding-left: 0.5em;
    background-color: #dcdcdc;
}

.header2style {
    color: #ffffff;
    padding: 0.5em;
    font-weight: normal;
    font-size: x-large;
}

.header3style {
    padding: 0.2em 0.2em 0.2em 1em;
}

.greyborder {
    border-bottom: 0.2em solid #808080;
    min-height: 0;
}

.paramsborder {
    margin-bottom: 0.5em;
    overflow-y: auto;
    line-height: 1.5em;
}

.upArrow {
    text-align: center;
    padding-top: 1em;
    font-size: larger;
    font-weight: bold;
    margin-top: 2.5em;
    position: relative;
    width: 4.9em;
    height: 3.5em;
    border-radius: 0% 0% 10% 10%;
}

.upArrow:before {
    content: '';
    border-width: 0em 2.4em 2.2em 2.5em;
    border-color: transparent;
    border-bottom-color: inherit;
    border-style: solid;
    position: absolute;
    width: 0em;
    height: 0em;
    left: 0em;
    top: -2.2em;
}

.downArrow {
    text-align: center;
    padding-top: 1.5em;
    font-size: larger;
    font-weight: bold;
    margin-bottom: 2.5em;
    position: relative;
    width: 4.9em;
    height: 3.5em;
    border-radius: 10% 10% 0% 0%;
}

.downArrow:before {
    content: '';
    border-width: 2.2em 2.5em 0em 2.4em;
    border-color: transparent;
    border-top-color: inherit;
    border-style: solid;
    position: absolute;
    width: 0em;
    height: 0em;
    left: 0em;
    bottom: -2.15em;
}

.noValue {
    text-align: center;
    padding-top: 1.8em;
    font-size: larger;
    font-weight: bold;
    width: 5em;
    height: 5em;
    border-radius: 50%;
}

.trends_legend {
    position: absolute;
    top: 5.8em;
    right: 0.5em;
}

.trends_legend_text {
    width: 13em;
    padding: 0.5em;
    background: #FFFFFF;
    border: 0.2em solid #F5F5F5;
    border-radius: 5%;
}

@media screen and (max-device-width: 840px) {
    .trends_legend_text {
        font-size: x-large;
    }
}

.upArrowLegend {
    border-bottom: 1em solid;
    border-bottom-color: inherit;
    border-right: 0.5em solid transparent !important;
    border-left: 0.5em solid transparent !important;
    display: inline-block;
}

.downArrowLegend {
    border-top: 1em solid;
    border-top-color: inherit;
    border-right: 0.5em solid transparent !important;
    border-left: 0.5em solid transparent !important;
    display: inline-block;
}

.noValueLegend {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    display: inline-block;
}

.viewdetail {
    text-align: center;
    display: block;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-weight: bold;
    text-transform: uppercase;
}

.viewsitedetail {
    text-align: center;
    display: block;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-weight: bold;
}

@media screen and (max-device-width: 840px) {
    .popup_table, .paramsborder, .viewdetail, .viewsitedetail, .params_table {
        font-size: xx-large !important;
    }

    .header2style {
        font-size: 200% !important;
    }

    .olPopup {
        min-width: 30em !important;
    }
}

.params_table {
    margin: auto;
    border-collapse: separate;
    border-spacing: 0.4em;
    min-width: 28em;
    padding-left: 0.5em;
    line-height: 1.2em;
}

.params_unit {
    color: #808080;
}

.table_title {
    vertical-align: top !important;
}

.params_icon {
    color: #FF0000;
}

.tablestyle {
    padding-left: 4em;
}
